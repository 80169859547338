import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/",
  "pageType": "card",
  "title": "About me",
  "backgroundImage": "abstract1"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I have been a professional programmer since 2005, but have been tinkering with computers for quite some time before then.
In that time I've used a lot of great technologies - and several that have fallen out of favour (I'm looking at you ASP, Flash, and VB6).`}</p>
    <p>{`Before I started my career in web programming, I earned my BSc (hons) degree in Computer Science and Neuroscience at Keele University.`}</p>
    <p>{`I am currently a member of the Operations and Security teams at iWeb Solutions where I work on a wide variety DevOps-style
projects around the infrastructure, tooling and compliance requirements of the company, rather than doing the day-to-day website
development that I previously did. This blog - if it materialises - is not necessarily about my work at iWeb, but from time to time
I may post something I learned or found interesting there.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      